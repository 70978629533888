<template>
    <div class="ontology_disease">
        <taskCommon
            title="本体病害"
            :currentTree="currentTree"
            :list="ycyslist"
            :snSearch="snSearch"
            :listConfig="listConfig"
            :defaultProps="defaultProps"
            widthValue="280px"
            @handleChange="handleChange"
        >
            <div
                v-show="diseaseRecord.length !== 0"
                class="diseaseBox scrollbar"
            >
                <div
                    v-for="item in diseaseRecord"
                    :key="item.Id"
                    class="diseaseItem"
                    :class="{ active: item.Id === selectedId }"
                >
                    <div class="name" @click="getJcDetail(item)">
                        {{ item.BHMC }}
                        <span v-if="+item.IsJcpz === 1" class="count">{{
                            item.PZCOUNT
                        }}</span>
                    </div>
                    <div v-if="+item.IsJcpz === 1" class="tag taghas">
                        (已配置监测任务)
                    </div>
                    <div v-else class="tag tagno">(未配置监测任务)</div>
                </div>
            </div>
            <slot>
                <Mymap
                    :mapData="mapData"
                    ref="myMap"
                    class="map"
                    @initmap="initMap"
                ></Mymap>
                <Dialog
                    ref="dialog"
                    :dialogData.sync="dialogData"
                    :ycysMc="ycysMc"
                    @closeDisesaseDialog="closeDisesaseDialog"
                    @saveSuccess="saveSuccess"
                >
                </Dialog>
            </slot>
        </taskCommon>
    </div>
</template>
<script>
import taskCommon from "../task-common";
import mixins from "../mixins";
import Dialog from "./ontology_disease_dialog";
import { mapActions } from "vuex";
export default {
    name: "ontology_disease",
    components: {
        taskCommon,
        Dialog,
    },
    mixins: [mixins],
    data() {
        return {
            mapData: {
                dxtState: true, //地形图
                maxZoom: 20,
                zoom: 12.5,
                areaState: true,
            },
            currentId: 70502,
            ycyslist: [],
            listConfig: {
                // name: 'YCYSMC',
                name: "MC",
                value: "YCYSBM",
                children: "CHILDREN",
            },
            defaultProps: {
                children: "CHILDREN",
                label: "MC",
                value: "YCYSBM",
            },
            basicMap: null,
            diseaseRecord: [], // 病害调查记录列表
            BHSYT: "", // 病害示意图
            BHLMTLJ: "", // 病害立面图
            ycysMc: "",
            selectedId: "",
            selectedIndex: 0,
            pointLayer: null,
        };
    },
    mounted() {
        this.dialogData.title = "病害监测配置";
        // this.getYcysList();
    },
    methods: {
        ...mapActions(["GetBhOrDtYcysList", "getJbtuData"]),
        handleClose() {},
        // handleChange() {
        //     this.dialogData.dialog = false;
        // },
        // 获得遗产要素列表
        async getYcysList() {
            let res = await this.GetBhOrDtYcysList({ ItemCode: 12 });
            this.ycyslist =
                res.ResultValue.sort((item1, item2) => {
                    return item2.JCSJCOUNT - item1.JCSJCOUNT;
                }) || [];
        },
        async changeList(val) {
            if (val[3]) {
                this.currentTree = val[0];
                this.$refs.myMap.changeFitBounds(val[4].WD, val[4].JD);
                this.ycysMc = val[1];
                this.selectedIndex = val[2];
                this.dialogData.title = `${this.ycysMc}-监测任务配置`;
                let res = await this.getJbtuData({ id: "12", ycysbm: val[0] });
                this.diseaseRecord = res;
            }
        },
        // 从列表视图点击 无法获得病害信息 获得监测配置详情
        getJcDetail(item) {
            this.selectedId = item.Id;
            this.dialogData.dialog = true;
            this.$refs.dialog.getDetail(item);
        },
        // 关闭弹框时 清除选中
        closeDisesaseDialog() {
            this.selectedId = "";
        },
        async initMap(map) {
            await this.getYcysList();
            this.basicMap = map;
            // eslint-disable-next-line no-undef
            this.pointLayer = L.layerGroup().addTo(this.basicMap);
            this.addMark();
        },
        addMark() {
            if (this.pointLayer) this.pointLayer.clearLayers();
            // let markers = [];
            // eslint-disable-next-line no-undef
            let myIcon1 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_default.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line no-undef
            let myIcon2 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_choose.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line no-undef
            let myIcon3 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_otherp.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            // eslint-disable-next-line no-undef
            let myIcon4 = L.icon({
                iconUrl: require("@image/monitoring_cloud/monomer_otherg.png"),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
            });
            if (this.ycyslist && this.ycyslist.length > 0) {
                this.ycyslist.forEach((item0, index0) => {
                    if (item0.CHILDREN && item0.CHILDREN.length > 0) {
                        item0.CHILDREN.forEach((item, index) => {
                            if (item.JD && item.WD) {
                                // eslint-disable-next-line no-undef
                                let marker = L.marker([item.WD, item.JD], {
                                    icon: item.JCSJCOUNT
                                        ? item.JCPZCOUNT
                                            ? myIcon3
                                            : myIcon1
                                        : myIcon4,
                                    attribution: item,
                                });
                                marker
                                    .on("mouseover", (e) => {
                                        // eslint-disable-next-line no-undef
                                        L.popup({
                                            closeOnClick: false,
                                            closeButton: false,
                                            offset: [0, 5],
                                            minWidth: 12,
                                        })
                                            .setLatLng([item.WD, item.JD])
                                            .setContent(item.MC)
                                            .openOn(this.basicMap);
                                        if (!item.JCPZCOUNT) {
                                            marker.setIcon(myIcon2);
                                        }
                                    })
                                    .on("mouseout", (e) => {
                                        this.basicMap.closePopup();
                                        if (!item.JCPZCOUNT) {
                                            marker.setIcon(
                                                item.JCSJCOUNT
                                                    ? item.JCPZCOUNT
                                                        ? myIcon3
                                                        : myIcon1
                                                    : myIcon4
                                            );
                                        }
                                    })
                                    .on("click", (e) => {
                                        // this.dialogData.dialog = true;
                                        // this.currentTree = item.YCYSBM;
                                        this.changeList([
                                            item.YCYSBM,
                                            item.MC,
                                            index,
                                            item.JCSJCOUNT,
                                            item,
                                        ]);
                                        // this.dialogData.title = item.YCYSMC + ' 病害监测配置';
                                        console.log(item, e);
                                        // this.getJcDetail(item)
                                    });
                                // markers.push(marker);
                                this.pointLayer.addLayer(marker);
                                if (item.JCPZCOUNT) {
                                    // eslint-disable-next-line no-undef
                                    let divIcon = L.divIcon({
                                        className: "my-div-icon",
                                        html:
                                            '<div style="width:16px;height:16px;background:red;color:#fff;border-radius:50%;line-height: 16px;text-align:center;">' +
                                            item.JCPZCOUNT +
                                            "</div>",
                                        iconAnchor: [-3, 25],
                                    });
                                    // eslint-disable-next-line no-undef
                                    let numMarker = L.marker(
                                        [item.WD, item.JD],
                                        {
                                            icon: divIcon,
                                        }
                                    );
                                    // markers.push(numMarker);
                                    this.pointLayer.addLayer(numMarker);
                                }
                                if (this.currentTree === item.YCYSBM) {
                                    this.$refs.myMap.changeFitBounds(
                                        item.WD,
                                        item.JD
                                    );
                                    L.popup({
                                        closeOnClick: false,
                                        closeButton: false,
                                        offset: [0, 5],
                                        minWidth: 12,
                                    })
                                        .setLatLng([item.WD, item.JD])
                                        .setContent(item.MC)
                                        .openOn(this.basicMap);
                                }
                            }
                        });
                    }
                });
            }

            // eslint-disable-next-line no-undef
            // L.featureGroup(markers).addTo(this.basicMap);
        },
        // 保存成功后 重新获得遗产要素列表
        async saveSuccess() {
            await this.getYcysList();
            let item = this.ycyslist[this.selectedIndex] || {};
            this.changeList([
                item.YCYSBM,
                item.MC,
                this.selectedIndex,
                item.JCSJCOUNT,
                { WD: item.WD, JD: item.JD },
            ]);
            this.addMark();
        },
    },
};
</script>
<style lang="scss" scoped>
.ontology_disease {
    .diseaseBox {
        z-index: 401;
        position: absolute;
        left: 292px;
        top: 50px;
        width: 240px;
        min-height: 40px;
        background: #fff;
        max-height: 400px;
        overflow: auto;
        .diseaseItem {
            text-align: left;
            padding: 10px 20px;
            .name {
                line-height: 20px;
                margin: 0 0 5px 0;
                cursor: pointer;
                .count {
                    float: right;
                    margin: 2px 5px;
                    width: 30px;
                    height: 20px;
                    line-height: 20px;
                    border-radius: 10px;
                    background: #ff9065;
                    color: white;
                    text-align: center;
                }
            }
            .tag {
                line-height: 18px;
                height: 18px;
            }
            .taghas {
                color: #03a9f4;
            }
            &.active {
                background-color: rgba(133, 162, 194, 0.9);
            }
        }
    }
}
</style>